import React from "react";
import WarningIcon from "assets/svgs/warning-sign_solid.svg";
import { Container, Section } from "components/style";
import * as SC from "./style";

const MidjourneyWarning = () => (
  <Section>
    <Container>
      <SC.StyledWrapper>
        <SC.WarningTag>
          Внимание
          <WarningIcon />
        </SC.WarningTag>
        <SC.Descr>Подскажем платёжный сервис для оплаты нейросети</SC.Descr>
      </SC.StyledWrapper>
    </Container>
  </Section>
);

export default MidjourneyWarning;
