/* eslint-disable */
import { useStaticQuery, graphql } from "gatsby";

const useMidjourneyPortfolio = () => {
  const query = useStaticQuery(graphql`
    query midjourneyPortfolio {
      poster1: file(relativePath: { eq: "midjourney/portfolio/01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster2: file(relativePath: { eq: "midjourney/portfolio/02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster3: file(relativePath: { eq: "midjourney/portfolio/03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster4: file(relativePath: { eq: "midjourney/portfolio/04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster5: file(relativePath: { eq: "midjourney/portfolio/05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster6: file(relativePath: { eq: "midjourney/portfolio/06.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster7: file(relativePath: { eq: "midjourney/portfolio/07.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster8: file(relativePath: { eq: "midjourney/portfolio/08.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster9: file(relativePath: { eq: "midjourney/portfolio/09.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster10: file(relativePath: { eq: "midjourney/portfolio/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poste11: file(relativePath: { eq: "midjourney/portfolio/11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster12: file(relativePath: { eq: "midjourney/portfolio/12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster13: file(relativePath: { eq: "midjourney/portfolio/13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster14: file(relativePath: { eq: "midjourney/portfolio/14.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const portfolio = Object.values(query);

  const data = [
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53572",
      src: portfolio[0].childImageSharp.fluid,
      author: "Алиса Брюква",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535722",
      src: portfolio[1].childImageSharp.fluid,
      author: "Алиса Брюква",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53573",
      src: portfolio[2].childImageSharp.fluid,
      author: "Алиса Брюква",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53574",
      src: portfolio[3].childImageSharp.fluid,
      author: "Алиса Брюква",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535а",
      src: portfolio[8].childImageSharp.fluid,
      author: "Екатерина Лукина",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53576",
      src: portfolio[5].childImageSharp.fluid,
      author: "Юлия Стрелкова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53577",
      src: portfolio[6].childImageSharp.fluid,
      author: "Юлия Стрелкова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53579",
      src: portfolio[7].childImageSharp.fluid,
      author: "Дмитрий Рашидов",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53575",
      src: portfolio[4].childImageSharp.fluid,
      author: "Анастасия Клейман",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53df",
      src: portfolio[10].childImageSharp.fluid,
      author: "Екатерина Махота",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2gr",
      src: portfolio[12].childImageSharp.fluid,
      author: "Павел Островлянчик",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2sf",
      src: portfolio[13].childImageSharp.fluid,
      author: "Алиса Брюква",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3faas",
      src: portfolio[11].childImageSharp.fluid,
      author: "Надежда Кудря",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3sd",
      src: portfolio[9].childImageSharp.fluid,
      author: "Виталий Куликов",
    },
  ];

  return data;
};

export default useMidjourneyPortfolio;
