import { Wrapper } from "components/style";
import styled from "styled-components";

export const WarningTag = styled.div`
  background: var(--granich-midjourney-color);
  padding: 0.15vw 0.4vw;
  font-size: 1.55vw;
  border-radius: 0.4vw;
  color: white;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 1.4vw;

  svg {
    fill: white;
    width: 1.3vw;
    margin-left: 0.4vw;
    position: relative;
    top: 0.05vw;
  }

  @media only screen and (max-width: 575px) {
    font-size: 4.6vw;
    padding: 0.5vw 1.5vw;
    border-radius: 1.5vw;
    margin-bottom: 2.5vw;

    svg {
      width: 4vw;
      margin-left: 1vw;
    }
  }
`;

export const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Descr = styled.div`
  font-size: 1.55vw;
  line-height: 1.47;
  text-align: center;
  font-weight: 500;

  @media only screen and (max-width: 575px) {
    font-size: 4.6vw;
  }
`;
