// react/gatsby imports
import React from "react";
// assets
import ogImage from "assets/images/seo/granich-midjourney.jpg";
import ogImageVK from "assets/images/seo/vk/granich-midjourney.jpg";
// data
import localData from "data/pages/granich-midjourney";
import teamData from "data/team";
// hooks
import useMidjourneyPortfolio from "hooks/portfolio/useMidjourneyPortfolio";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";
// hooks
import useMidjourneyQuery from "hooks/graphql/useMidjourneyQuery";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import CourseAuthors from "components/pages/course-page/course-authors";
import CoursePriceRange from "components/pages/course-page/price-range";
import MidjourneyOffer from "components/pages/course-page/granich-midjourney/offer";
import FoundationPortfolio from "components/pages/foundation-of-graphic-design/foundation-portfolio";
import MidjourneyWarning from "components/pages/course-page/granich-midjourney/warning";
import PaymentChoices from "components/pages/payment-choices";

const GranichMidjourneyPage = () => {
  const prismicData = useMidjourneyQuery();
  const portfolio = useMidjourneyPortfolio();
  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};

  const alice = teamData.find(({ name }) => name === "Алиса Брюква");
  alice.teacherCategory = "Автор";

  const authors = [
    {
      authorInfo: alice,
      authorDescription: (
        <>
          <p>
            Проводит лекции, проверяет домашние задания, помогает докручивать
            проекты
          </p>
          <p>Дизайнер маркетинговых коммуникаций Мвидео и Эльдорадо</p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <div className="section-top-block" />
      <SEO
        title="Granich Midjourney"
        description={
          seoData?.seodescription?.text ||
          "Научимся использовать нейросеть midjourney в качестве инструмента графического дизайна"
        }
        keywords={["midjourney", "нейросеть", "гранич", "granich"]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.granichMidjourney)}
      />
      <MidjourneyOffer data={data} />
      <MidjourneyWarning />
      <FoundationPortfolio
        courseTitle={data.coursetitle?.text}
        portfolio={portfolio}
        customTitle="Некоторые работы с мастер-класса"
      />
      {authors && (
        <CourseAuthors
          authors={authors}
          title="Мастер-класс ведёт:"
          themeColor="var(--granich-midjourney-color)"
        />
      )}
      <div id="participation-section" />
      <CoursePriceRange
        formId="ltForm4630442"
        priceRange={localData.priceRange}
        courseTitle={data.coursetitle?.text}
        formPostUrl="https://school.granich.design/pl/lite/block-public/process-html?id=1804331043"
      />
      <PaymentChoices courseType="Мастер-класс" />
    </Layout>
  );
};

export default GranichMidjourneyPage;
