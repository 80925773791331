import styled from "styled-components";
import { Descr, Wrapper } from "components/pages/course-page/offer/style";
import MidjourneyLogo from "assets/svgs/courses-order/midjourney.svg";
import Img from "gatsby-image";

export const StyledWrapper = styled(Wrapper)`
  position: relative;
  top: -2vw;
  padding-top: 5vw;

  @media only screen and (max-width: 575px) {
    padding-top: 7vw;
    top: 0;
  }
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin-bottom: 0 !important;
  }

  @media only screen and (max-width: 575px) {
    padding: 0 3.5vw;
    margin-bottom: 2vw;
  }
`;

export const MobileCompositionWrapper = styled.div`
  @media only screen and (max-width: 575px) {
    min-height: 80vw;
    height: 80vw;
    width: 100%;
    position: relative;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    margin: 0;
  }
`;

export const StyledDescr = styled(Descr)`
  text-align: center;
  width: 46vw;
  margin-top: 1.5vw;

  @media only screen and (max-width: 575px) {
    width: 100%;
    margin-top: 0;
  }
`;

export const StyledMainIllustration = styled(Img)`
  width: 100%;
  border-radius: 1.5vw;

  @media only screen and (max-width: 575px) {
    border-radius: 3.5vw;
  }
`;

export const MainIllustrationWrapper = styled.div`
  padding: 0.5vw;
  background: white;
  border-radius: 1.5vw;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.15);
  transition: transform ease 0.2s;
  position: relative;
  z-index: 2;

  :hover {
    cursor: pointer;
    transform: scale(1.01);
  }

  @media only screen and (max-width: 575px) {
    margin-bottom: 4vw;
    margin-top: 4vw;
    border-radius: 3.5vw;
    padding: 1vw;
    box-shadow: 0 0vw 3vw rgba(0, 0, 0, 0.3);

    :hover {
      transform: none;
    }
  }
`;

export const MidjourneyLogoWrapper = styled.div`
  border-radius: 50%;
  background: white;
  padding: 1vw;
  width: 6.3vw !important;
  height: 6.3vw !important;

  svg {
    top: 0.2vw;
    position: relative;
  }

  @media only screen and (max-width: 575px) {
    width: 12vw !important;
    height: 12vw !important;
    padding: 1.8vw;
  }
`;

export const StyledTitle = styled.h1`
  color: var(--granich-midjourney-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 5.5vw;
  letter-spacing: -0.2vw;
  margin: 2vw 0 0.2vw 0;
  span {
    display: block;
    font-weight: bold;
    font-size: 2.8vw;
  }

  @media only screen and (max-width: 575px) {
    font-size: 12vw;
    margin: 4vw 0 3vw 0;

    span {
      font-size: 7.5vw;
    }
  }
`;

export const MidjourneyLogoBorder = styled.div`
  position: absolute;
  top: 6.5vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background: linear-gradient(
    150.73deg,
    #cc01ff -84.63%,
    rgba(204, 1, 255, 0) 82.04%
  );
  box-shadow: 0 0 0.5vw #94146775;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.1vw !important;
  min-width: 7.1vw !important;
  height: 7.1vw !important;

  @media only screen and (max-width: 575px) {
    width: 14vw !important;
    min-width: 14vw !important;
    height: 14vw !important;
  }
`;

export const StyledMidjourneyLogo = styled(MidjourneyLogo)``;
